
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { ValidationResult } from './../_models/validation-result';
import { SentidoRota } from 'app/_enums/sentido-rota.enum';
import { BaseService } from './base.service';
import { Porto } from './../_models/porto';
import { Anel } from './../_models/anel';

@Injectable({
    providedIn: 'root'
})
export class AnelRotaService {

    constructor(private baseService: BaseService) { }

    // ---- Anéis / Rotas ------------------------------------------
    getAneis(): Observable<Anel[]> {
        return this.baseService.Get('anelRota').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Anel[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    salvarAnel(anel: Anel): Observable<ValidationResult> {
        if (!anel.anelID || anel.anelID == 0)
            return this.baseService.Post('anelRota', anel).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
        else
            return this.baseService.Put('anelRota/' + anel.anelID, anel).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
    }

    deletarAnel(id: number): Observable<ValidationResult> {
        return this.baseService.Delete('anelRota/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getAnel(id: number): Observable<Anel> {
        return this.baseService.Get('anelRota/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Anel;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getString(sentido: SentidoRota): string {
        switch (sentido) {
            case SentidoRota.Norte:
                return "Norte";
            case SentidoRota.Sul:
                return "Sul";
        }
    }
    // -------------------------------------------------------------
}
